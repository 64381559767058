import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

import LsPageComponent from "../components/lsPageComponent"

const LSPage = () => (
  <div>
    <Helmet title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 03 -- Die Spurensuche; CEO Fraud: Helfen Sie das Geld zurückzuholen!" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, CEO Fraud, Spurensuche" />
  </Helmet>
    <LsPageComponent 
      ls="3" 
      game="ls3"
      test="true"
      questionairs="false"
      motto="Die Spurensuche"
      chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/609bd1c6b21c7e0013a3c310/609bd2609067520014aa26d0/Chapter_1_46.zip"/>
   </div>
)

export default LSPage
